import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

const page404Content = graphql`
  {
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            about_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bgImg: file(relativePath: { eq: "o6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const NotFoundPage = () => {
  const { bgImg, heroBgImg } = useStaticQuery(page404Content)
  const page404HeroImg =
    heroBgImg.edges[0].node.acf.about_hero.localFile.childImageSharp.fluid
  const bgImage = bgImg.childImageSharp.fluid

  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  useEffect(() => {
    //GSAP Animations
    const headerAnimation = gsap.timeline()

    headerAnimation
      .set(headerContainer.current, {
        visibility: "visible",
      })
      .from([headerLogo.current, headerMenu.current], {
        duration: 0.5,
        y: 10,
        autoAlpha: 0,
        ease: "power3.inOut",
        stagger: 0.2,
        delay: 2,
      })
  }, [])

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title="404: Not found" />
      <Hero
        heroTitle={`NOT FOUND`}
        heroSubtitle={`This path doesn't exist, look inward...`}
        heroBgImg={page404HeroImg}
        bgImg={bgImage}
      />
    </Layout>
  )
}

export default NotFoundPage
